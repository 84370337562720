.button {
  @extend %h4;
  display: block;
  padding: 0.75rem 1rem;
  cursor: pointer;
  border: 0;
  outline: 0;
  white-space: nowrap;

  &.google-login {
    background-color: $white;
    border-radius: 2px;
    color: $black;
    padding-left: 2.5rem;
    background-image:url('/images/google.png');
    background-size: 1rem;
    background-position: 0.75rem 0.75rem;
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  &.text-button {
    padding: 0;
    line-height: 1rem;
  }

  &.black {
    background-color: $dark-gray;
    border-radius: 2px;
    color: $white;
  }

  &.orange {
    background-color: $orange;
    color: $white;
  }

  &:hover {
    border: 0;
    outline: 0;
  }
}
