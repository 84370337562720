/* Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
--------------------------------------------------------------------------------------- */
/* line 3, /Users/dan/working/shortener-web/app/styles/lib/reset.min.scss */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* line 3, /Users/dan/working/shortener-web/app/styles/lib/reset.min.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

/* line 3, /Users/dan/working/shortener-web/app/styles/lib/reset.min.scss */
body {
  line-height: 1; }

/* line 3, /Users/dan/working/shortener-web/app/styles/lib/reset.min.scss */
ol, ul {
  list-style: none; }

/* line 3, /Users/dan/working/shortener-web/app/styles/lib/reset.min.scss */
blockquote, q {
  quotes: none; }

/* line 3, /Users/dan/working/shortener-web/app/styles/lib/reset.min.scss */
blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

/* line 3, /Users/dan/working/shortener-web/app/styles/lib/reset.min.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 11, /Users/dan/working/shortener-web/app/styles/_typography.scss */
h1, nav .site-title, nav .page-title {
  font-size: 2.25rem;
  font-family: "Inconsolata", monospace;
  font-weight: 400;
  letter-spacing: 0.5px; }
  /* line 17, /Users/dan/working/shortener-web/app/styles/_typography.scss */
  h1.site-title, nav .site-title {
    font-size: 1.3rem;
    line-height: 0.8rem; }
  /* line 22, /Users/dan/working/shortener-web/app/styles/_typography.scss */
  h1.page-title, nav .page-title {
    font-size: 1rem;
    line-height: 1rem; }

/* line 28, /Users/dan/working/shortener-web/app/styles/_typography.scss */
h2 {
  font-family: "Inconsolata", monospace;
  font-size: 1.75rem; }
  /* line 32, /Users/dan/working/shortener-web/app/styles/_typography.scss */
  h2.page-subtitle {
    font-size: 0.65rem;
    line-height: 1; }

/* line 38, /Users/dan/working/shortener-web/app/styles/_typography.scss */
h3 {
  font-family: "Inconsolata", monospace;
  font-size: 1rem; }

/* line 43, /Users/dan/working/shortener-web/app/styles/_typography.scss */
h4, .button, .new-button {
  font-size: 0.875rem;
  line-height: 1rem;
  vertical-align: middle;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase; }

/* line 52, /Users/dan/working/shortener-web/app/styles/_typography.scss */
h5 {
  font-family: "Inconsolata", monospace;
  font-size: 0.65rem; }

/* line 57, /Users/dan/working/shortener-web/app/styles/_typography.scss */
p, form .form-input input[type="text"], form .form-input input::-webkit-input-placeholder {
  font-size: 1rem; }

/* line 61, /Users/dan/working/shortener-web/app/styles/_typography.scss */
strong {
  font-weight: 500; }

@media screen and (max-width: 640px) {
  /* line 66, /Users/dan/working/shortener-web/app/styles/_typography.scss */
  h1, nav .site-title, nav .page-title {
    font-size: 1.75rem; }
  /* line 70, /Users/dan/working/shortener-web/app/styles/_typography.scss */
  h4, .button, .new-button {
    font-size: 0.75rem; } }

/* line 1, /Users/dan/working/shortener-web/app/styles/_base.scss */
html, body {
  background-color: #424242;
  color: #FFFFFF;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  line-height: 1.5;
  position: relative; }

/* line 12, /Users/dan/working/shortener-web/app/styles/_base.scss */
*, *:before, *:after {
  box-sizing: border-box; }

/* line 16, /Users/dan/working/shortener-web/app/styles/_base.scss */
a, a:hover {
  color: inherit;
  text-decoration: none; }

/* line 21, /Users/dan/working/shortener-web/app/styles/_base.scss */
img {
  max-width: 100%; }

/* line 5, /Users/dan/working/shortener-web/app/styles/_layout.scss */
.content-wrapper {
  background-color: #E9E9E9; }

/* line 9, /Users/dan/working/shortener-web/app/styles/_layout.scss */
.content {
  max-width: 58.75rem;
  margin: 0 auto; }
  /* line 26, /Users/dan/working/shortener-web/app/styles/_base.scss */
  .content:after {
    clear: both;
    content: ' ';
    display: block; }
  /* line 14, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  .content section {
    background-color: #FFFFFF;
    padding: 1.5rem; }

/* line 20, /Users/dan/working/shortener-web/app/styles/_layout.scss */
.centered-content {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  justify-content: center; }

/* line 27, /Users/dan/working/shortener-web/app/styles/_layout.scss */
.app {
  height: 100%; }

/* line 31, /Users/dan/working/shortener-web/app/styles/_layout.scss */
.splash-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }
  /* line 38, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  .splash-content h1 {
    margin-bottom: 1rem; }
  /* line 42, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  .splash-content p {
    margin-bottom: 1rem; }

/* line 47, /Users/dan/working/shortener-web/app/styles/_layout.scss */
.sidebar {
  background-color: #424242;
  bottom: 0;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 3rem;
  width: 15rem;
  z-index: 3; }
  /* line 57, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  .sidebar::-webkit-scrollbar {
    display: none; }

/* line 62, /Users/dan/working/shortener-web/app/styles/_layout.scss */
.sidebar-scrim {
  background-color: rgba(233, 233, 233, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 3rem;
  transition: 0.3s ease opacity;
  z-index: 2; }

/* line 75, /Users/dan/working/shortener-web/app/styles/_layout.scss */
.details {
  background-color: #E9E9E9;
  bottom: 0;
  color: #212121;
  left: 15rem;
  position: absolute;
  right: 0;
  top: 3rem;
  z-index: 1; }

@media screen and (max-width: 640px) {
  /* line 87, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  .content {
    padding: 0;
    width: 100%; }
  /* line 92, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  .details {
    left: 0; }
  /* line 96, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  .sidebar {
    transform: translate3d(-100%, 0, 0);
    transition: 0.3s ease all; }
  /* line 101, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  .content-wrapper.sidebar-open {
    overflow: hidden; }
  /* line 106, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  .sidebar-open .sidebar {
    transform: none; }
  /* line 110, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  .sidebar-open .sidebar-scrim {
    opacity: 1;
    pointer-events: auto; }
  /* line 116, /Users/dan/working/shortener-web/app/styles/_layout.scss */
  section {
    padding-left: 2.5rem;
    padding-right: 2.5rem; } }

@media screen and (min-width: 1921px) {
  /* line 2, /Users/dan/working/shortener-web/app/styles/_responsive.scss */
  html, body {
    font-size: 20px; } }

@media screen and (max-width: 1920px) {
  /* line 8, /Users/dan/working/shortener-web/app/styles/_responsive.scss */
  html, body {
    font-size: 18px; } }

@media screen and (max-width: 1440px) {
  /* line 14, /Users/dan/working/shortener-web/app/styles/_responsive.scss */
  html, body {
    font-size: 16px; } }

@media screen and (max-width: 1024px) {
  /* line 20, /Users/dan/working/shortener-web/app/styles/_responsive.scss */
  html, body {
    font-size: 15px; } }

@media screen and (min-width: 641px) {
  /* line 26, /Users/dan/working/shortener-web/app/styles/_responsive.scss */
  .mobile-only {
    display: none; } }

@media screen and (max-width: 640px) {
  /* line 32, /Users/dan/working/shortener-web/app/styles/_responsive.scss */
  html, body {
    font-size: 14px; }
  /* line 36, /Users/dan/working/shortener-web/app/styles/_responsive.scss */
  .hide-mobile {
    display: none; } }

@media screen and (max-width: 480px) {
  /* line 42, /Users/dan/working/shortener-web/app/styles/_responsive.scss */
  html, body {
    font-size: 16px; } }

@media screen and (max-width: 319px) {
  /* line 48, /Users/dan/working/shortener-web/app/styles/_responsive.scss */
  html, body {
    font-size: 7px; } }

@media screen and (max-width: 240px) {
  /* line 54, /Users/dan/working/shortener-web/app/styles/_responsive.scss */
  html, body {
    font-size: 5px; } }

/* line 1, /Users/dan/working/shortener-web/app/styles/_buttons.scss */
.button {
  display: block;
  padding: 0.75rem 1rem;
  cursor: pointer;
  border: 0;
  outline: 0;
  white-space: nowrap; }
  /* line 10, /Users/dan/working/shortener-web/app/styles/_buttons.scss */
  .button.google-login {
    background-color: #FFFFFF;
    border-radius: 2px;
    color: #212121;
    padding-left: 2.5rem;
    background-image: url("/images/google.png");
    background-size: 1rem;
    background-position: 0.75rem 0.75rem;
    background-repeat: no-repeat;
    vertical-align: middle; }
  /* line 22, /Users/dan/working/shortener-web/app/styles/_buttons.scss */
  .button.text-button {
    padding: 0;
    line-height: 1rem; }
  /* line 27, /Users/dan/working/shortener-web/app/styles/_buttons.scss */
  .button.black {
    background-color: #424242;
    border-radius: 2px;
    color: #FFFFFF; }
  /* line 33, /Users/dan/working/shortener-web/app/styles/_buttons.scss */
  .button.orange {
    background-color: #FF9800;
    color: #FFFFFF; }
  /* line 38, /Users/dan/working/shortener-web/app/styles/_buttons.scss */
  .button:hover {
    border: 0;
    outline: 0; }

/* line 1, /Users/dan/working/shortener-web/app/styles/_navbar.scss */
nav {
  align-items: center;
  background-color: #212121;
  box-shadow: rgba(33, 33, 33, 0.117647) 0px 1px 6px, rgba(33, 33, 33, 0.117647) 0px 1px 4px;
  display: flex;
  height: 3rem;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; }
  /* line 21, /Users/dan/working/shortener-web/app/styles/_navbar.scss */
  nav .nav-content {
    padding: 1rem 1.5rem; }
  /* line 25, /Users/dan/working/shortener-web/app/styles/_navbar.scss */
  nav .nav-sidebar {
    width: 15rem; }
  /* line 29, /Users/dan/working/shortener-web/app/styles/_navbar.scss */
  nav .nav-spacer {
    flex: 1; }
  /* line 33, /Users/dan/working/shortener-web/app/styles/_navbar.scss */
  nav .hamburger {
    display: none;
    margin-left: 1rem;
    width: 1.5rem;
    height: 1.2rem;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0; }
    /* line 42, /Users/dan/working/shortener-web/app/styles/_navbar.scss */
    nav .hamburger .bar {
      width: 100%;
      height: 3px;
      background-color: #FFFFFF; }

@media screen and (max-width: 640px) {
  /* line 51, /Users/dan/working/shortener-web/app/styles/_navbar.scss */
  nav {
    padding: 0 0.75rem; }
    /* line 54, /Users/dan/working/shortener-web/app/styles/_navbar.scss */
    nav .hamburger {
      display: flex;
      margin: 0 0.75rem; }
    /* line 59, /Users/dan/working/shortener-web/app/styles/_navbar.scss */
    nav .site-title {
      font-size: 1.2rem; }
    /* line 63, /Users/dan/working/shortener-web/app/styles/_navbar.scss */
    nav .nav-content {
      padding: 0 0.75rem; } }

/* line 1, /Users/dan/working/shortener-web/app/styles/_form.scss */
.form-wrapper {
  background-color: #FFFFFF;
  width: 100%;
  padding: 0 1.5rem; }

/* line 7, /Users/dan/working/shortener-web/app/styles/_form.scss */
form {
  display: flex;
  align-items: center;
  height: 5rem; }
  /* line 12, /Users/dan/working/shortener-web/app/styles/_form.scss */
  form .text-size-calculator {
    visibility: hidden;
    position: absolute; }
  /* line 17, /Users/dan/working/shortener-web/app/styles/_form.scss */
  form .form-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0.75rem;
    position: relative; }
    /* line 24, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form .form-input.form-input-slug input {
      box-sizing: content-box;
      padding-right: 0; }
    /* line 29, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form .form-input.form-input-longurl {
      flex: 1;
      padding-left: 1.25rem; }
    /* line 34, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form .form-input:last-child {
      margin-bottom: 0; }
    /* line 38, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form .form-input .input-prefix {
      line-height: 1.5;
      margin-top: 0.35rem;
      padding-left: 0.75rem;
      position: absolute;
      left: 0.75rem;
      top: 0;
      bottom: 0; }
      /* line 47, /Users/dan/working/shortener-web/app/styles/_form.scss */
      form .form-input .input-prefix + input {
        padding-left: 3.6rem; }
    /* line 52, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form .form-input input {
      line-height: 1.5;
      padding: 0.25rem 0.75rem;
      margin: 0;
      border: 0;
      outline: 0;
      transition: 0.2s linear border-color;
      width: 100%; }
      /* line 61, /Users/dan/working/shortener-web/app/styles/_form.scss */
      form .form-input input[type="text"] {
        border: 1px solid transparent; }
        /* line 65, /Users/dan/working/shortener-web/app/styles/_form.scss */
        form .form-input input[type="text"]:hover {
          border-color: rgba(33, 33, 33, 0.2); }
        /* line 69, /Users/dan/working/shortener-web/app/styles/_form.scss */
        form .form-input input[type="text"]:focus {
          border-color: #212121; }
      /* line 78, /Users/dan/working/shortener-web/app/styles/_form.scss */
      form .form-input input[type="submit"] {
        transition: 0.3s ease opacity;
        opacity: 0;
        height: 2rem; }
    /* line 86, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form .form-input.has-errors input, form .form-input.has-errors input:focus, form .form-input.has-errors input:hover {
      color: #F44336;
      border-color: #F44336; }
    /* line 91, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form .form-input.has-errors .error-text {
      display: block; }
    /* line 96, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form .form-input .error-text {
      position: absolute;
      top: 100%;
      min-width: 10rem;
      left: 1.5rem;
      padding-top: 0.25rem;
      color: red;
      display: none; }
  /* line 109, /Users/dan/working/shortener-web/app/styles/_form.scss */
  form.can-save .form-input input[type="submit"] {
    opacity: 1; }
  /* line 113, /Users/dan/working/shortener-web/app/styles/_form.scss */
  form.can-save .form-input input[type="text"] {
    border-color: rgba(33, 33, 33, 0.2); }
    /* line 116, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form.can-save .form-input input[type="text"]:focus {
      border-color: #212121; }
  /* line 122, /Users/dan/working/shortener-web/app/styles/_form.scss */
  form.can-save .form-input.has-errors input, form.can-save .form-input.has-errors input:focus, form.can-save .form-input.has-errors input:hover {
    color: #F44336;
    border-color: #F44336; }

@media screen and (max-width: 640px) {
  /* line 132, /Users/dan/working/shortener-web/app/styles/_form.scss */
  form {
    flex-direction: column;
    height: auto;
    padding: 1.5rem 0; }
    /* line 137, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form .arrow {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      font-weight: 700;
      font-size: 1.5rem; }
    /* line 146, /Users/dan/working/shortener-web/app/styles/_form.scss */
    form .form-input {
      width: 100%;
      margin: 0 0 1rem;
      padding: 0; }
      /* line 151, /Users/dan/working/shortener-web/app/styles/_form.scss */
      form .form-input.form-input-slug {
        padding: 0;
        width: calc(100% - 3rem);
        margin-right: 3rem; }
      /* line 157, /Users/dan/working/shortener-web/app/styles/_form.scss */
      form .form-input.form-input-longurl {
        padding: 0; }
      /* line 161, /Users/dan/working/shortener-web/app/styles/_form.scss */
      form .form-input .input-prefix {
        top: 0.25rem;
        left: 0; }
      /* line 166, /Users/dan/working/shortener-web/app/styles/_form.scss */
      form .form-input input[type="text"] {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin: 0;
        box-sizing: border-box;
        min-width: 100%;
        max-width: 100%;
        border: 1px solid #E9E9E9; }
      /* line 176, /Users/dan/working/shortener-web/app/styles/_form.scss */
      form .form-input input[type="submit"] {
        opacity: 1;
        margin: 0; } }

/* line 1, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
.shortlink-list {
  padding-top: 5rem; }
  /* line 4, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
  .shortlink-list .shortlink-list-item {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #636363;
    cursor: pointer;
    user-select: none; }
    /* line 10, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
    .shortlink-list .shortlink-list-item .shortlink-list-item-long-url {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%; }
  /* line 18, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
  .shortlink-list .active .shortlink-list-item {
    background-color: #E9E9E9;
    border-right: 1px solid #424242;
    color: #212121; }

/* line 25, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
.new-button {
  align-items: center;
  background-color: #FFFFFF;
  border-right: 1px solid #E9E9E9;
  color: #212121;
  display: flex;
  height: 5rem;
  left: 0;
  padding: 0 1.5rem;
  position: absolute;
  top: 0;
  width: 15rem; }
  /* line 39, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
  .new-button .text-wrapper {
    display: block; }
    /* line 42, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
    .new-button .text-wrapper .text {
      display: block;
      transition: 0.3s ease transform; }
    /* line 47, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
    .new-button .text-wrapper::after {
      display: block;
      background-color: #212121;
      content: ' ';
      height: 2px;
      transform: scale(0, 1);
      transform-origin: 0 0;
      transition: 0.3s ease transform; }
  /* line 59, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
  .new-button:hover {
    color: #212121; }
    /* line 63, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
    .new-button:hover .text-wrapper .text {
      transform: translate3d(0, -2px, 0); }
    /* line 67, /Users/dan/working/shortener-web/app/styles/_shortlink-list.scss */
    .new-button:hover .text-wrapper::after {
      transform: scale(1, 1); }

/* line 1, /Users/dan/working/shortener-web/app/styles/_shortlink-detail.scss */
.detail-canvas {
  padding: 1.5rem;
  background: #E9E9E9;
  position: relative; }
  /* line 6, /Users/dan/working/shortener-web/app/styles/_shortlink-detail.scss */
  .detail-canvas section + section {
    border-top: 1px solid #E9E9E9; }

/* line 11, /Users/dan/working/shortener-web/app/styles/_shortlink-detail.scss */
.click-counts {
  display: flex;
  width: 100%; }
  /* line 15, /Users/dan/working/shortener-web/app/styles/_shortlink-detail.scss */
  .click-counts .click-count {
    margin-right: 1.5rem;
    flex: 1; }
    /* line 19, /Users/dan/working/shortener-web/app/styles/_shortlink-detail.scss */
    .click-counts .click-count:last-child {
      margin-right: 0; }

@media screen and (max-width: 640px) {
  /* line 26, /Users/dan/working/shortener-web/app/styles/_shortlink-detail.scss */
  .click-counts {
    flex-wrap: wrap;
    margin-bottom: -1.5rem; }
    /* line 30, /Users/dan/working/shortener-web/app/styles/_shortlink-detail.scss */
    .click-counts .click-count {
      max-width: 50%;
      width: 10rem;
      margin: 0 0 1.5rem;
      padding: 0 0.75rem;
      display: flex;
      flex-shrink: 0;
      align-items: stretch;
      flex-direction: column;
      justify-content: space-between; }
      /* line 41, /Users/dan/working/shortener-web/app/styles/_shortlink-detail.scss */
      .click-counts .click-count h3 {
        max-width: 100%;
        width: 10rem; }
      /* line 46, /Users/dan/working/shortener-web/app/styles/_shortlink-detail.scss */
      .click-counts .click-count:nth-child(even) {
        padding-right: 0; }
      /* line 50, /Users/dan/working/shortener-web/app/styles/_shortlink-detail.scss */
      .click-counts .click-count:nth-child(odd) {
        padding-left: 0; } }
