$white: #FFFFFF;
$lightest-gray: #E9E9E9;
$lighter-gray: #D5D5D5;
$light-gray: #9E9E9E;
$medium-gray: #636363;
$dark-gray: #424242;
$black: #212121;
$orange: #FF9800;
$red: #F44336;
$transparent: rgba(0, 0, 0, 0);
