// Font weights
$extra-bold: 800;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;

$sans: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$monospace: 'Inconsolata', monospace;

h1, %h1 {
  font-size: 2.25rem;
  font-family: $monospace;
  font-weight: $regular;
  letter-spacing: 0.5px;

  &.site-title {
    font-size: 1.3rem;
    line-height: 0.8rem;
  }

  &.page-title {
    font-size: 1rem;
    line-height: 1rem;
  }
}

h2, %h2 {
  font-family: $monospace;
  font-size: 1.75rem;

  &.page-subtitle {
    font-size: 0.65rem;
    line-height: 1;
  }
}

h3, %h3 {
  font-family: $monospace;
  font-size: 1rem;
}

h4, %h4 {
  font-size: 0.875rem;
  line-height: 1rem;
  vertical-align: middle;
  font-weight: $bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h5, %h5 {
  font-family: $monospace;
  font-size: 0.65rem;
}

p, %p {
  font-size: 1rem;
}

strong {
  font-weight: $medium;
}

@media screen and (max-width: $phone-landscape-max) {
  h1, %h1 {
    font-size: 1.75rem;
  }

  h4, %h4 {
    font-size: 0.75rem;
  }
}
