$content-width: 58.75rem;
$navbar-height: 3rem;
$sidebar-width: 15rem;

.content-wrapper {
  background-color: $lightest-gray;
}

.content {
  @include clearfix;
  max-width: $content-width;
  margin: 0 auto;

  section {
    background-color: $white;
    padding: 1.5rem;
  }
}

.centered-content {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  justify-content: center;
}

.app {
  height: 100%;
}

.splash-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h1 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
}

.sidebar {
  background-color: $dark-gray;
  bottom: 0;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: $navbar-height;
  width: $sidebar-width;
  z-index: 3;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebar-scrim {
  background-color: rgba($lightest-gray, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: $navbar-height;
  transition: 0.3s ease opacity;
  z-index: 2;
}

.details {
  background-color: $lightest-gray;
  bottom: 0;
  color: $black;
  left: $sidebar-width;
  position: absolute;
  right: 0;
  top: $navbar-height;
  z-index: 1;
}

@media screen and (max-width: $phone-landscape-max) {
  .content {
    padding: 0;
    width: 100%;
  }

  .details {
    left: 0;
  }

  .sidebar {
    transform: translate3d(-100%, 0, 0);
    transition: 0.3s ease all;
  }

  .content-wrapper.sidebar-open {
    overflow: hidden;
  }

  .sidebar-open {
    .sidebar {
      transform: none;
    }

    .sidebar-scrim {
      opacity: 1;
      pointer-events: auto;
    }
  }

  section {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
