.detail-canvas {
  padding: 1.5rem;
  background: $lightest-gray;
  position: relative;

  section + section {
    border-top: 1px solid $lightest-gray;
  }
}

.click-counts {
  display: flex;
  width: 100%;

  .click-count {
    margin-right: 1.5rem;
    flex: 1;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .click-counts {
    flex-wrap: wrap;
    margin-bottom: -1.5rem;

    .click-count {
      max-width: 50%;
      width: 10rem;
      margin: 0 0 1.5rem;
      padding: 0 0.75rem;
      display: flex;
      flex-shrink: 0;
      align-items: stretch;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        max-width: 100%;
        width: 10rem;
      }

      &:nth-child(even) {
        padding-right: 0;
      }

      &:nth-child(odd) {
        padding-left: 0;
      }
    }
  }
}
